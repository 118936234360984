<template>
  <div class="desktopTerminals">
    <div class="w">
      <div class="title" ref="desktopTerminals">
        <div class="big">Desktop Terminals</div>
        <!-- <div class="small">ACE Series</div> -->
      </div>
      <div class="ACESeries">
        <div class="itemOne" @click="goRouter('/ACE1')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">ACE 1</div>
            <div class="text">Smart Desktop</div>
            <div class="text">Terminal</div>
          </div>
        </div>
        <div class="itemTwo" @click="goRouter('/ACE1Pro')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">ACE 1 Pro</div>
            <div class="text">Smart Desktop</div>
            <div class="text">Terminal</div>
          </div>
        </div>
        <div class="itemThree" @click="goRouter('/ACE2')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">ACE 2</div>
            <div class="text">Smart Desktop</div>
            <div class="text">Terminal</div>
          </div>
        </div>
        <div class="itemFour" @click="goRouter('/D1')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">D1</div>
            <div class="text">Smart Desktop</div>
            <div class="text">Terminal</div>
          </div>
        </div>
        <div class="itemFive" @click="goRouter('/Y1')">
          <div class="img"></div>
          <div class="bottom">
            <div class="title">Y1</div>
            <div class="text">Smart Desktop</div>
            <div class="text">Terminal</div>
          </div>
        </div>
      </div>
      <!-- <div class="DSeries">
        <div class="smallTitle">D Series</div>
        <div class="itemBox">
          <div class="itemOne" @click="goRouter('/D1')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">D1</div>
              <div class="text">Smart Desktop</div>
              <div class="text">Terminal</div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="YSeries">
        <div class="smallTitle">Y Series</div>
        <div class="itemBox">
          <div class="itemOne" @click="goRouter('/Y1')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">Y1</div>
              <div class="text">Smart Desktop</div>
              <div class="text">Terminal</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="ESeries" ref="smartWeighing">
        <div class="bigTitle">Smart Weighing</div>
        <div class="itemBox">
          <div class="itemOne" @click="goRouter('/ET7')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">ET7</div>
              <div class="text">Smart Weighing</div>
              <div class="text">Terminal</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Kiosks" ref="Kiosks">
        <div class="bigTitle">Kiosks</div>
        <!-- <div class="smallTitle">MK Series</div> -->
        <div class="itemBox">
          <div class="itemOne" @click="goRouter('/MK215')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">MK 215</div>
              <div class="text">Smart Kiosk</div>
              <div class="text">Terminal</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Peripherals" ref="Peripherals">
        <div class="bigTitle">Peripherals</div>
        <!-- <div class="smallTitle">MK Series</div> -->
        <div class="itemBox">
          <div class="itemOne" @click="goRouter('/9533Series')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">Agility 9533 Series</div>
              <div class="text">2D Wireless</div>
              <div class="text">Barcode Scanner</div>
            </div>
          </div>
          <div class="itemTwo" @click="goRouter('/9520Series')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">Agility 9520 Series</div>
              <div class="text">2D Wired</div>
              <div class="text">Barcode Scanner</div>
            </div>
          </div>
          <div class="itemThree" @click="goRouter('/A90')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">Agility A-90</div>
              <div class="text">2D Desktop Barcode</div>
              <div class="text">Scanner</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Printer" ref="Printer">
        <div class="bigTitle">Printer</div>
        <!-- <div class="smallTitle">MK Series</div> -->
        <div class="itemBox">
          <div class="itemOne" @click="goRouter('/Sprint800')">
            <div class="img"></div>
            <div class="bottom">
              <div class="title">Sprint 800</div>
              <div class="text">Desktop Printer</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 接收方页面的组件中
import { EventBus } from '@/utils/eventBus.js'
export default {
  data() {
    return {
      path: '' //当前路由
    }
  },
  mounted() {
    this.path = this.$route.path
    EventBus.$on(this.path, data => {
      // 处理事件
      this.toLocal(data)
    })
  },
  methods: {
    toLocal(Id) {
      //锚点存在跳转
      if (Id) {
        const top1 = this.$refs[Id].getBoundingClientRect().top
        // 获取滚动条的位置
        const top2 =
          document.body.scrollTop || document.documentElement.scrollTop
        // 计算元素距视窗的实际高度
        const top = top1 + top2
        // 滚动到相应位置
        window.scrollTo(0, top - localStorage.getItem('header'))
      }
    },
    goRouter(val) {
      this.$router.push({ path: val })
    }
  },
  beforeDestroy() {
    EventBus.$off(this.path)
  }
}
</script>

<style lang="less" scoped>
.desktopTerminals {
  padding-top: 105px;
  background: rgba(0, 0, 0, 1);
  .title {
    text-align: center;
    .big {
      color: rgba(255, 255, 255, 1);
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
    }
    .small {
      margin: 25px 0px 34px 0px;
      color: rgba(0, 92, 255, 1);
      font-size: 24px;
      font-weight: 700;
      line-height: 34.75px;
    }
  }
  .bottom {
    height: 76px;
    .title {
      text-align: center;
      margin: 0px 0px 6px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      font-weight: 500;
      line-height: 28.96px;
    }
    .text {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 20.27px;
      font-weight: 500;
    }
  }
  .ACESeries {
    margin: 94px auto 0px;
    width: 894.9px;
    display: flex;
    flex-flow: wrap;
    .itemOne {
      margin: 0px 19px 33px 0px;
      cursor: pointer;
      width: 285px;
      height: 320px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 51px 0px 21.38px 0px;
        transition: all 0.3s;
        height: 124.62px;
        width: 223.37px;
        background: url('../../../assets/下拉栏配图/ACE 1/透明底.png') no-repeat
          center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/ACE 1/透明底2.png')
            no-repeat center;
          background-size: cover;
        }
      }
    }
    .itemTwo {
      margin: 0px 19px 33px 0px;
      cursor: pointer;
      width: 285px;
      height: 320px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 47px 0px 17.27px 0px;
        transition: all 0.3s;
        height: 132.73px;
        width: 122.32px;
        background: url('../../../assets/下拉栏配图/ACE 1 Pro/透明底.png')
          no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/ACE 1 Pro/透明底2.png')
            no-repeat center;
          background-size: cover;
        }
      }
    }
    .itemThree {
      margin: 0px 0px 33px 0px;
      cursor: pointer;
      width: 285px;
      height: 320px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 52px 0px 21.56px 0px;
        transition: all 0.3s;
        width: 163.83px;
        height: 123.44px;
        background: url('../../../assets/下拉栏配图/ACE 2/2.png') no-repeat
          center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/ACE 2/1.png') no-repeat
            center;
          background-size: cover;
        }
      }
    }
    .itemFour {
      margin: 0px 19px 33px 0px;
      cursor: pointer;
      width: 285px;
      height: 320px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 51px 0px 21.38px 0px;
        transition: all 0.3s;
        height: 124.62px;
        width: 223.37px;
        background: url('../../../assets/下拉栏配图/D1/2.png') no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/D1/1.png') no-repeat
            center;
          background-size: cover;
        }
      }
    }
    .itemFive {
      cursor: pointer;
      width: 285px;
      height: 320px;
      border-radius: 20px;
      background: rgba(46, 50, 59, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        margin: 48px 0px 18.1px 0px;
        transition: all 0.3s;
        height: 130.9px;
        width: 140.03px;
        background: url('../../../assets/下拉栏配图/Y1/2.png') no-repeat center;
        background-size: cover;
        &:hover {
          transform: scale(1.05);
          background: url('../../../assets/下拉栏配图/Y1/1.png') no-repeat
            center;
          background-size: cover;
        }
      }
    }
  }
  .DSeries {
    .smallTitle {
      text-align: center;
      margin: 52px 0px 34px 0px;
      color: rgba(0, 92, 255, 1);
      font-size: 24px;
      font-weight: 700;
      line-height: 34.75px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 51px 0px 21.38px 0px;
          transition: all 0.3s;
          height: 124.62px;
          width: 223.37px;
          background: url('../../../assets/下拉栏配图/D1/2.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/D1/1.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
  .YSeries {
    .smallTitle {
      text-align: center;
      margin: 52px 0px 34px 0px;
      color: rgba(0, 92, 255, 1);
      font-size: 24px;
      font-weight: 700;
      line-height: 34.75px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 48px 0px 18.1px 0px;
          transition: all 0.3s;
          height: 130.9px;
          width: 140.03px;
          background: url('../../../assets/下拉栏配图/Y1/2.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/Y1/1.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
  .ESeries {
    .bigTitle {
      text-align: center;
      margin: 126px 0px 72px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 47px 0px 17.36px 0px;
          transition: all 0.3s;
          height: 132.64px;
          width: 98.92px;
          background: url('../../../assets/下拉栏配图/ET 7/2.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/ET 7/3.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
  .Kiosks {
    .bigTitle {
      text-align: center;
      margin: 154px 0px 72px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 27px 0px 22px 0px;
          transition: all 0.3s;
          height: 168px;
          width: 194px;
          background: url('../../../assets/下拉栏配图/MK 215/默认变量.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/下拉栏配图/MK 215/变量 2.png')
              no-repeat center;
            background-size: cover;
          }
        }
      }
    }
  }
  .Peripherals {
    .bigTitle {
      text-align: center;
      margin: 154px 0px 72px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        margin-right: 20px;
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 27px 0px 7px 0px;
          transition: all 0.3s;
          height: 179px;
          width: 179px;
          background: url('../../../assets/下拉栏配图/Agility9533Series/下拉栏配图.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background-size: cover;
          }
        }
      }
      .itemTwo {
        margin-right: 20px;
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 27px 0px 3px 0px;
          transition: all 0.3s;
          height: 187px;
          width: 187px;
          background: url('../../../assets/下拉栏配图/Agility9520Series/正面.png.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background-size: cover;
          }
        }
      }
      .itemThree {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 27px 0px 3px 0px;
          transition: all 0.3s;
          height: 187px;
          width: 187px;
          background: url('../../../assets/下拉栏配图/A90/产品导航栏.png')
            no-repeat center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background-size: cover;
          }
        }
      }
    }
  }
  .Printer {
    .bigTitle {
      text-align: center;
      margin: 154px 0px 72px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 36px;
      font-weight: 700;
      line-height: 52.13px;
    }
    .itemBox {
      display: flex;
      justify-content: center;
      .itemOne {
        cursor: pointer;
        width: 285px;
        height: 320px;
        border-radius: 20px;
        background: rgba(46, 50, 59, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          margin: 27px 0px 22px 0px;
          transition: all 0.3s;
          height: 159px;
          width: 159px;
          background: url('../../../assets/Sprint800配图/背面.png') no-repeat
            center;
          background-size: cover;
          &:hover {
            transform: scale(1.05);
            background: url('../../../assets/Sprint800配图/正面.png') no-repeat
              center;
            background-size: cover;
          }
        }
      }
    }
  }
}
</style>
